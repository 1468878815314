import React, { useState, useEffect, createContext,useContext, useCallback } from "react";
import "./LoginPage.css";
import Maindashboard from "../../MainDashBoard_Page/Main_Dashboard";
import "./LoginForm.css";
import configData from "../../../config.json";
import LoadingComponent from "../../loading_component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ColorContext } from "../../../ColorContext";


export const EmplContext = createContext();

const LoginPage = () => {
  const [resultData, setResultData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [isemphr, setIsemphr] = useState(false);
  const [isempwfh, setIsempwfh] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { setBgColor, setTextColor, pageColor , setPageColor } = useContext(ColorContext);
  const [isempmanager, setIsempmanager] = useState(false);


  const fetchData = useCallback(async (storedUsername, storedPin) => {
    // console.log("Fetching:--")
    if (storedUsername) {
      try {
        setLoading(true);
        const url = `${configData.SERVER_URL}/login`;
        const secretKey = configData.SECRET_Key;
        const body = JSON.stringify({
          username: storedUsername,
          pin: storedPin,
          secretKey,
        });
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: body,
        });
        const data = await response.json();
        const resultData = JSON.parse(data.result);
        // console.log("reresultData====",resultData)
        if (resultData.success === true) {
          setResultData(resultData);
          // console.log("resultData.theme_color",resultData.theme_color)
          // console.log("resultData.theme_color",resultData.theme_color.split(',')[0])
          setBgColor(resultData.theme_color.split(',')[0]);
          setTextColor(resultData.theme_color.split(',')[1]);
          setPageColor(resultData.theme_color.split(',')[2]);
          handleLoggin(resultData.name);
          setIsemphr(resultData.Isemphr)
          setIsempwfh(resultData.Isempwfh)
          setIsempmanager(resultData.Isempmanager)
          // if (resultData.Isemphr === true) {
          //   setIsemphr(true);
          // } else {
          //   setIsemphr(false);
          // }
        } else if (
          resultData.success === false &&
          resultData.message === "Wrongpassword"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please enter correct pin.",
          });
          localStorage.clear();
        } else if (
          resultData.success === false &&
          resultData.message === "AccessDenied"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please enter correct credentials",
          });
          localStorage.clear();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedPin = localStorage.getItem("pin");
    fetchData(storedUsername, storedPin);

    const storedAuth = localStorage.getItem("authenticated");
    if (storedAuth) {
      setAuthenticated(JSON.parse(storedAuth));
    }
  }, [fetchData]);

  const handleLoggin = (name) => {
    setAuthenticated(true);
    localStorage.setItem("authenticated", JSON.stringify(true));
    // console.log("called")
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    localStorage.setItem("username", username);
    localStorage.setItem("pin", pin);
    fetchData(username, pin);
  };

  // console.log("Object.keys(resultData).length ===",Object.keys(resultData).length)
  return (
    <div className="login-container" style={{backgroundColor:pageColor}}>
      <>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {authenticated ? (
              <>
                <EmplContext.Provider
                  value={{ resultData, setAuthenticated, isemphr, isempwfh, fetchData, isempmanager }}
                >
                  <Maindashboard />
                </EmplContext.Provider>
              </>
            ) : (
              <div className="hrms_form">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="">
                        <img
                          src="Vector.png"
                          className="img-fluid"
                          alt="vector img"
                        />
                      </div>
                    </div>
                    <>
                      <div className="col-lg-6 col-md-6 hrms_right_lform_sd">
                        <div className="hrms_form_cont">
                          <div className="hrms_form_ttl">
                            <h2>Login</h2>
                          </div>
                          <form
                            style={{ textAlign: "left" }}
                            onSubmit={handleLogin}
                          >
                            <div className="mb-3 hrms_form_box">
                              <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={username}
                                onChange={handleUsernameChange}
                                autoComplete="example.email@gmail.com"
                                placeholder="example.email@gmail.com"
                                fullWidth
                                required
                              />
                            </div>
                            <div className="mb-1 hrms_form_box">
                              <TextField
                                margin="dense"
                                id="pin"
                                name="pin"
                                label="Pin"
                                type={showPassword ? "text" : "password"}
                                value={pin}
                                onChange={handlePinChange}
                                autoComplete="Pin"
                                fullWidth
                                required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="mb-4">
                              <div
                                className="form-check"
                                style={{ textAlign: "end", fontSize: 12 }}
                              >
                                <Link
                                  to="/forget_password"
                                  className="nav-link"
                                >
                                  <span
                                    className="float-end"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#006d7c",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Forget Pin?
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn hrms_login_btn w-100 mb-3 mt-3"
                            >
                              Login
                            </button>
                          </form>
                        </div>
                        <div className="second" />
                        <div className="third" />
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default LoginPage;