import React, { useContext, useState, useEffect } from "react";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import './Company_policy.css';

function Company() {
  const [policies, setPolicies] = useState([]);
  const [activeFile, setActiveFile] = useState(null); // Stores active file for dropdown
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [load, setLoading] = useState(true);

  useEffect(() => {
    fetchPolicies();
  }, [employee_id]);

  const fetchPolicies = async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/companyPolicy`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);

      if (result.success) {
        setPolicies(result.policy_data || []);
      } else {
        console.error("Error fetching policies:", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  finally {
    setLoading(false);
  }
  };

  const downloadFile = (base64Data, fileName) => {
    const link = document.createElement("a");
    link.href = `data:application/octet-stream;base64,${atob(base64Data)}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleDropdown = (event, file) => {
    event.stopPropagation(); // Prevents event bubbling
    setActiveFile(activeFile === file ? null : file);
  };
  useEffect(() => {
    const handleClickOutside = () => {
      setActiveFile(null); // Hide buttons when clicking anywhere else
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const groupedPolicies = policies.reduce((acc, policy) => {
    if (!acc[policy.category]) acc[policy.category] = [];
    acc[policy.category].push(policy);
    return acc;
  }, {});

  return (
    <>
    {load ? (
      <div className="">
        {/* <HrDashboard_loading /> */}
      </div>
    ) : (
    <div className="company-container">
      <div className="al_holi">
        <h2 className="al_holi_tl">Company Policy</h2>
      </div>
      {Object.keys(groupedPolicies).length > 0 ? (
        <div>
          {Object.keys(groupedPolicies).map((category) => (
            <div key={category} className="policy-category">
              <h5 className="category-name">{category}</h5>
              <div className="policy-list">
                {groupedPolicies[category].map((policy, index) => (
                  <div key={index} className="policy-item-container">
                    <div onClick={(event) => toggleDropdown(event, policy)} className="policy-item">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                        alt="document"
                        width="30px"
                      />
                      <p className="policy-name">{policy.name}</p>
                    </div>
                    {activeFile === policy && (
                      <div className="overlay-buttons">
                        <button onClick={() => window.open(`data:application/pdf;base64,${atob(policy.file)}`, "_blank")} className="overlay-btn open-btn">
                          Open
                        </button>
                        <button onClick={() => downloadFile(policy.file, policy.name)} className="overlay-btn download-btn">
                          Download
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-policies-message">No policies found</p>
      )}
    </div>
    )}
    </>
  );
}

export default Company;
