import React, { useState, useEffect, useContext } from "react";
import HrDashboard_loading from "../HrDashboard/HrDashboard_loading";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import { NavLink } from "react-router-dom";
import { CustomerService } from "./service/CustomerService";
import "./ManagerDasboard.css";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

export default function TeamTimesheet() {
  const [customers, setCustomers] = useState([]);
  const [load, setLoading] = useState(true);
  const { resultData } = useContext(EmplContext);
  const { textColor } = useContext(ColorContext);
  const employee_id = resultData.employee_id || 0;
  const [performances, setperformances] = useState([]);
  const [expandedEmployee, setExpandedEmployee] = useState([]);
  const [expandedDate, setExpandedDate] = useState({});
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isAllDatesExpanded, setIsAllDatesExpanded] = useState(false);

  const toggleEmployee = (employeeName) => {
    setExpandedEmployee((prev) => {
      if (!Array.isArray(prev)) {
        prev = [];
      }
      return prev.includes(employeeName) ? prev.filter((name) => name !== employeeName) : [...prev, employeeName];
    });
  };

  const toggleDate = (employeeName, date) => {
    setExpandedDate((prev) => ({
      ...prev,
      [employeeName]: prev[employeeName] === date ? null : date,
    }));
  };

  const handleTimesheetAction = async (timesheet_id, action, performanceRating = "", managerComments = "") => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, timesheet_id, action, performanceRating, managerComments };
      const response = await fetch(`${configData.SERVER_URL}/handletimesheet`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      if (result.success) {
        Swal.fire({ icon: "success", title: result.message });
      } else {
        Swal.fire({ icon: "error", title: result.message });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      fetchData();
    }
  };

  const fetchData = async () => {
    const secretKey = configData.SECRET_Key;
    try {
      const data = await CustomerService.getCustomersMedium(employee_id, secretKey);
      // setCustomers(data.pending_timesheet_list);
      // console.log("customers", data.pending_timesheet_list);
      // setperformances(data.performance_list.performances);
      // console.log("performance", data.performance_list.performances);
      if (data?.pending_timesheet_list) {
        setCustomers(data.pending_timesheet_list);
      } else {
        setCustomers([]); 
      }

      setperformances(data.performance_list || []);
    } catch (error) {
      Swal.fire({ icon: "error", title: error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [resultData.employee_id]);

  const unfoldAllEmployeesAndDates = () => {
    const newAllExpanded = !isAllExpanded;
    setIsAllExpanded(newAllExpanded);
    setExpandedEmployee((prev) => (newAllExpanded ? customers.map((timesheet) => timesheet[8]) : []));
    setIsAllDatesExpanded(newAllExpanded);
  };

  return (
    <>
      <div className="logo-hr-dashboard">
        <img src="company_logo.png" alt="Profile Pic" className="img-xs brand-logo-image" />
      </div>
      {load ? (
        <div className="">
        {/* <HrDashboard_loading /> */}
      </div>
      ) : (
        <div className="content-wrapper">
          <div className="ep_content">
            <div className="row" style={{ paddingTop: 70 }}>
              <div style={{ textAlign: "right" }}>
                <button className="btn " onClick={unfoldAllEmployeesAndDates} style={{ borderRadius: "25px", backgroundColor: textColor }}>
                  {isAllExpanded ? "Collapse All" : "Unfold All"}
                </button>
              </div>
              <div className="col-md-12">
                <div className="tabs-container">
                  <NavLink to="/TeamTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{ textDecoration: "none" }}>
                    Pending Timesheet
                  </NavLink>
                  <NavLink to="/ApprovedTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{ textDecoration: "none" }}>
                    Approved Timesheet
                  </NavLink>
                  <NavLink to="/RejectedTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{ textDecoration: "none" }}>
                    Rejected Timesheet
                  </NavLink>
                </div>
                <div className="atable">
                  <table id="basicTable" className="basic-table">
                    <tbody>
                      {customers && Array.isArray(customers) && Object.entries(
                        customers.reduce((acc, timesheet) => {
                          {/* {customers && Array.isArray(customers) ? Object.entries(
                        customers.reduce((acc, timesheet) => { */}
                          const employeeName = timesheet[8];
                          const date = timesheet[9];

                          if (!acc[employeeName]) acc[employeeName] = {};
                          if (!acc[employeeName][date]) acc[employeeName][date] = [];

                          acc[employeeName][date].push(timesheet);
                          return acc;
                        }, {})
                      ).map(([employeeName, dateGroups], empIndex) => (
                        <React.Fragment key={empIndex}>
                          <tr
                            className="employee-row"
                            onClick={() => toggleEmployee(employeeName)}
                            style={{
                              backgroundColor: expandedEmployee.includes(employeeName) ? textColor : "initial",
                              cursor: "pointer",
                              fontWeight: "bold",
                              color: expandedEmployee.includes(employeeName) ? "textColor" : "textColor",
                            }}
                          >
                            <td colSpan="9" style={{ textAlign: "left", backgroundColor: textColor, color: "white" }}>{employeeName}</td>
                          </tr>
                          {(isAllExpanded || expandedEmployee.includes(employeeName)) &&
                            Object.entries(dateGroups).map(([date, timesheets], dateIndex) => (
                              <React.Fragment key={dateIndex}>
                                <tr
                                  className="date-row"
                                  onClick={() => toggleDate(employeeName, date)}
                                  style={{
                                    backgroundColor:
                                      expandedDate[employeeName] === date ? "textColor" : "textColor",
                                    cursor: "pointer",
                                  }}
                                >
                                  <td colSpan="9" style={{ textAlign: "left" }}>{date}</td>
                                </tr>
                                {(isAllDatesExpanded || expandedDate[employeeName] === date) && (
                                  <tr>
                                    <td colSpan="9">
                                      <table>
                                        <thead>
                                          <tr role="row" style={{ backgroundColor: textColor, color: "white" }}>
                                            <th className="center">S.No</th>
                                            <th className="center">Project</th>
                                            <th className="center">Task Description</th>
                                            <th className="center">From</th>
                                            <th className="center">To</th>
                                            <th className="center">Hours Spent</th>
                                            <th className="center">Comments</th>
                                            <th className="center">Task Status</th>
                                            <th className="center">M.Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {timesheets.map((timesheet, idx) => (
                                            <tr key={idx}>
                                              <td>{idx + 1}</td>
                                              <td>{timesheet[0]}</td>
                                              <td>{timesheet[1]}</td>
                                              <td>{timesheet[2]}</td>
                                              <td>{timesheet[3]}</td>
                                              <td>{timesheet[4]}</td>
                                              <td>{timesheet[5]}</td>
                                              <td>{timesheet[6]}</td>
                                              <td>
                                                {/* <button className="btn btn-primary" onClick={() => handleTimesheetAction(timesheet[7], "Approved")}>
                                                  Approve
                                                </button> */}
                                                <FaCheck className="" onClick={() => handleTimesheetAction(timesheet[7], "Approved")} style={{ marginRight: "20px", color: "green" }} />
                                                {/* <button className="btn btn-secondary m-1" onClick={() => handleTimesheetAction(timesheet[7], "Rejected")}>
                                                  Reject
                                                </button> */}
                                                <FaTimes className="" onClick={() => handleTimesheetAction(timesheet[7], "Rejected")} style={{ color: "red" }} />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                        <tr>
                                          <td colSpan="2" style={{ border: "none", textAlign: "left" }}>
                                            <div>
                                              Performance
                                              <div>
                                                <select id={`performance-${date}`} className="meet-expect">
                                                  <option value="">Select Performance</option>
                                                  {Array.isArray(performances) && performances.length > 0 ? (
                                                    performances.map((performance) => (
                                                      <option key={performance.id} value={performance.id}>
                                                        {performance.name}
                                                      </option>
                                                    ))
                                                  ) : (
                                                    <option disabled>No performances available</option>
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </td>
                                          <td colSpan="6" style={{ border: "none", textAlign: "left" }}>
                                            <div style={{ position: "relative" }}>
                                              Manager Comments
                                              <div>
                                                <input
                                                  id={`comments-${date}`}
                                                  type="text"
                                                  style={{ width: "100%", borderRadius: "8px", border: "1px solid #80808063" }}
                                                  placeholder="Input Text"
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td colSpan="2" style={{ border: "none" }}>
                                            <div style={{ marginTop: "19px" }}>
                                              <button
                                                style={{
                                                  backgroundColor: textColor,
                                                  color: "white",
                                                  padding: "5px 8px",
                                                  border: "none",
                                                  borderRadius: "5px",
                                                  fontSize: "13px",
                                                }}

                                                onClick={() => {
                                                  const performanceRating = document.querySelector(`#performance-${date}`)?.value || "";
                                                  const managerComments = document.querySelector(`#comments-${date}`)?.value || "";

                                                  timesheets.forEach((timesheet) => {
                                                    handleTimesheetAction(timesheet[7], "Approved", performanceRating, managerComments);
                                                  });
                                                }}
                                              >
                                                Approve All
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}  <tr style={{ height: "10px" }}></tr>
                        </React.Fragment>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

