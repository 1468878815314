import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import "./Sidebar.css";
import { ColorContext } from "../../ColorContext";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import eventEmitter from '../../Eventemitter';


function SidebarManager() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [emplinfo, setEmplinfo] = useState({
    company_link: "",
  });

  const [activeIndex, setActiveIndex] = useState();

  const location = useLocation();

useEffect(() => {
  const pathToIndexMap = {
    "/": 1,
    "/AllLeaveRequest": 2,
    "/TeamTimesheet": 3,
    "/TeamExpenses": 4,
    "/TeamAssets" : 5,
    "/RejectedTimesheet": 3,
    "/ApprovedTimesheet" :3,
  };

  const currentPath = location.pathname;
  const newIndex = pathToIndexMap[currentPath] || 1; // Default to Dashboard
  setActiveIndex(newIndex);
}, [location.pathname]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const { bgColor, textColor, pageColor } = useContext(ColorContext);


  const [isSidebarManagerToggled, setIsSidebarManagerToggled] = useState(false);

  useEffect(() => {
    const handleToggleSidebarManager = () => {
      setIsSidebarManagerToggled((prevState) => !prevState);
    };

    eventEmitter.on('toggleSidebarManager', handleToggleSidebarManager);

    // Clean up the event listener on component unmount
    return () => {
      eventEmitter.off('toggleSidebarManager', handleToggleSidebarManager);
    };
  });


  return (
    <nav 
    className={`sidebar sidebar-offcanvas ${isSidebarManagerToggled ? 'toggle-sidebar' : ''}`}
    id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <img
          src="company_logo.png"
          alt="Profile Pic"
          className="img-xs brand-logo-image "
        />
      </div>
      <ul className="nav mt-5 menu-sidebar">

        <li
          key={1}
          className={`nav-item menu-items ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => handleItemClick(1)}
          style={activeIndex === 1 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/" className="nav-link">
            <span className="menu-icon">
              <i className="fas fa-glass-cheers" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor  }}>
              All Leaves
            </span>
          </Link>
        </li>
        <li
          key={2}
          className={`nav-item menu-items ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => handleItemClick(2)}
          style={activeIndex === 2 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/AllLeaveRequest" className="nav-link">
            <span className="menu-icon">
              <i className=" 	fa fa-bed" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              All Leave Request
            </span>
          </Link>
        </li>
        
        <li
          key={3}
          className={`nav-item menu-items ${activeIndex === 3 ? "active" : ""}`}
          onClick={() => handleItemClick(3)}
          style={activeIndex === 3 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/TeamTimesheet" className="nav-link" >
            <span className="menu-icon">
              <i
                className="far fa-calendar-alt"
                style={{ color: textColor }}
              />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Team Timesheet
            </span>
          </Link>
        </li>
        <li
          key={4}
          className={`nav-item menu-items ${activeIndex === 4 ? "active" : ""}`}
          onClick={() => handleItemClick(4)}
          style={activeIndex === 4 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/TeamExpenses" className="nav-link">
            <span className="menu-icon">
            <i className="fa-solid fa-indian-rupee-sign" style={{ color: textColor }}></i>
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Team Expenses
            </span>
          </Link>
        </li>
        <li
          key={5}
          className={`nav-item menu-items ${activeIndex === 5 ? "active" : ""}`}
          onClick={() => handleItemClick(5)}
          style={activeIndex === 5 ? { backgroundColor: bgColor } : {}}
        >
          <Link to="/TeamAssets" className="nav-link">
            <span className="menu-icon">
              <i className="fa-solid fa-computer" style={{ color: textColor }} />
            </span>
            <span className="menu-title" style={{ color: textColor }}>
              Team Assets
            </span>
          </Link>
        </li>

          <li
          key={6}
          className={`nav-item menu-items settings-fixed ${activeIndex === 6 ? "active" : ""}`}
          onClick={() => handleItemClick(6)}
          style={activeIndex === 6 ? { backgroundColor: bgColor } : {}}
        >
          <Dropdown>
            <MenuButton
              style={{
                width: "92%",
                padding: "0px",
                border: 0
              }}
              className="MenuButton"
            >
              <span
                className="nav-link" 
                // to="/myasset"
                style={{
                  marginRight: "auto",
                }}
              >
                <span className="menu-icon">
                  <i
                    className="fa-solid fa-gears"
                    style={{ color: textColor }}
                  />
                </span>
                <span className="menu-title " style={{ color: textColor }}>
                  Settings
                </span>
              </span>
            </MenuButton>
            <Menu>
              <MenuItem>
                <span
                  className=""
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  Color Customization
                </span>
              </MenuItem>
            </Menu>
          </Dropdown>
        </li>

      </ul>
    </nav>
  );
}

export default SidebarManager;
