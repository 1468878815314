import React, { useContext, useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import "./MyTimesheet.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Input from "@mui/joy/Input";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Form from "react-bootstrap/Form";
import { format, parse } from "date-fns";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { ColorContext } from "../../ColorContext";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

function PendingApproval() {
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [load, setLoading] = useState(false);
  const [timesheet, setTimesheet] = useState([]);
  const [projects, setProjects] = useState([]);
  const [taskstatus, setTaskstatus] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const [filterDate, setFilterDate] = useState(new Date());
  const [filteredTimesheet, setFilteredTimesheet] = useState([]);
  const { textColor } = useContext(ColorContext);
  const [selectedOption, setSelectedOption] = useState("1");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  function showFilterS() {
    setShowFilter(!showFilter);
  }
  useEffect(() => {
    setLoading(true);
  }, []);

  const fetchTimesheet = useCallback(async () => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/pending_timesheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      if (result.success === true) {
        setTimesheet(result.timesheet_records);
        setLoading(false);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [employee_id]);

  useEffect(() => {
    fetchTimesheet();
  }, [fetchTimesheet]);

  const getFormattedDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const selectedDate = getFormattedDate(filterDate);
    const filtered = timesheet.filter((record) => record[0] === selectedDate);
    setFilteredTimesheet(filtered);
  }, [filterDate, timesheet]);

//  useEffect(() => {
//   const now = new Date(); // Current date
//   const currentMonth = now.getMonth(); // Current month (0-11)
//   const currentYear = now.getFullYear(); // Current year

//   const filtered = timesheet.filter((record) => {
//     // Parse the date from the record
//     let recordDate;
//     try {
//       // Assuming record[0] is in "dd-MM-yyyy" format, adjust this if needed
//       const [day, month, year] = record[0].split("-").map(Number);
//       recordDate = new Date(year, month - 1, day); // Month is 0-indexed
//     } catch (error) {
//       console.error("Error parsing date from record:", record[0], error);
//       return false; // Skip this record if date parsing fails
//     }

//     // Debug: Log the parsed date and check if it matches
//     console.log("Record Date:", recordDate);

//     // Check if the record's month and year match the current month and year
//     return (
//       recordDate.getMonth() === currentMonth &&
//       recordDate.getFullYear() === currentYear
//     );
//   });

//   console.log("Filtered Timesheets:", filtered); // Debug filtered results

//   setFilteredTimesheet(filtered); // Update the filtered timesheets
// }, [timesheet]);

useEffect(() => {
  const now = new Date();
  let fromDate, toDate;
  if (selectedOption === "1") {
    fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
    toDate = new Date();
  } else if (selectedOption === "2") {
    fromDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    toDate = new Date(now.getFullYear(), now.getMonth(), 0);
  } else if (selectedOption === "3") {
    fromDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
    toDate = new Date(now.getFullYear(), now.getMonth(), 0);
  } else if (selectedOption === "6") {
    fromDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
    toDate = new Date(now.getFullYear(), now.getMonth(), 0);
  } else if (selectedOption === "range" && dateFrom && dateTo) {
    fromDate = new Date(dateFrom);
    toDate = new Date(dateTo);
  }

  const filtered = timesheet.filter((record) => {
    const [day, month, year] = record[0].split("-").map(Number);
    const recordDate = new Date(year, month - 1, day);
    return recordDate >= fromDate && recordDate <= toDate;
  });

  setFilteredTimesheet(filtered);
}, [timesheet, selectedOption, dateFrom, dateTo]);

const handleChange = (e) => {
  setSelectedOption(e.target.value);
};

  const defaultRow = {
    id: 1,
    date: new Date(),
    project: "",
    task: "",
    status: "",
    timeFrom: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
    timeTo: dayjs().hour(0).minute(0).tz("Asia/Kolkata"),
    hours: "",
    comments: "",
  };
  const [rows, setRows] = useState([defaultRow]);

  const convertDecimalToHHMMSS = (decimalHours) => {
    const totalSeconds = Math.floor(decimalHours * 3600);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const formatDate = (date) => {
    return format(date, "dd/MM/yyyy");
  };

  return (
    <>
      {load ? (
        <div className="main-panel">
          <LoadingComponent />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="" style={{ gap: "10px", textAlign: "left" ,paddingBottom:"40px"}}>
                    {/* <div className="showFilter-btn" onClick={showFilterS}>
                      <img
                        src="filter.jpg"
                        style={{
                          height: 15,
                          margin: "7px 0px 10px 16px",
                        }}
                        alt="Filter icon"
                      />
                      <p
                        htmlFor="options"
                        style={{
                          marginBottom: "0px",
                          lineHeight: "29px",
                        }}
                      >
                        Filter
                      </p>
                    </div>

                    <div
                      className={`show-filter-timesheet mt-3 ${
                        showFilter ? "show-filter" : ""
                      }`}
                    >
                      <DatePicker
                        reduceAnimations
                        selected={filterDate}
                        label="Filter-Date"
                        onChange={(date) => setFilterDate(date)}
                        required
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div> */}
                  <div className="showFilter-btn" onClick={showFilterS}>
                        <img
                          src="filter.jpg"
                          style={{
                            height: 15,
                            margin: "7px 0px 10px 16px",
                          }}
                        />
                        <p
                          htmlFor="options"
                          style={{
                            marginBottom: "0px",
                            lineHeight: "29px",
                          }}
                        >
                          Filter
                        </p>
                      </div>
                    </div>
                    <div
                      className={`das-filtitle mt-3 ${
                        showFilter ? "show-filter" : ""
                      }`} style={{paddingBottom:"40px"}}
                    >
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="currentMonth"
                          name="filterOption"
                          value="1"
                          checked={selectedOption === "1"}
                          onChange={handleChange}
                        />
                        <label htmlFor="currentMonth">Current Month</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastMonth"
                          name="filterOption"
                          value="2"
                          checked={selectedOption === "2"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastMonth">Last Month</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastThreeMonths"
                          name="filterOption"
                          value="3"
                          checked={selectedOption === "3"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastThreeMonths">Last 3 Months</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastSixMonths"
                          name="filterOption"
                          value="6"
                          checked={selectedOption === "6"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastSixMonths">Last 6 Months</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="customRange"
                          name="filterOption"
                          value="range"
                          checked={selectedOption === "range"}
                          onChange={handleChange}
                        />
                        <label htmlFor="customRange">Custom Range</label>
                      </div>
                    </div>
                    <div
                      className={`das-filtitle ${
                        showFilter ? "show-filter" : ""
                      }`}
                    >
                      {selectedOption === "range" && (
                        <div
                          className="mt-3"
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <label htmlFor="dateFrom">Date From:</label>
                          <input
                            type="date"
                            id="dateFrom"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                          />
                          <label htmlFor="dateTo">Date To:</label>
                          <input
                            type="date"
                            id="dateTo"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                        <div className="tabs-container">
                          <NavLink
                            to="/MyTimesheet"
                            className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                            style={{textDecoration:"none"}}
                          >
                            My Timesheet
                          </NavLink>
                          <NavLink
                            to="/PendingApproval"
                            className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                            style={{textDecoration:"none"}}
                          >
                            Pending Approval
                          </NavLink>
                          <NavLink
                            to="/Approved"
                            className={({ isActive }) => (isActive ? 'tab active' : 'tab')}
                            style={{textDecoration:"none"}}
                          >
                            Approved
                          </NavLink>
                        </div>
                  <div className="">
                    <div className="atable">
                      <Form>
                      <table id="basicTable" className="basic-table">
                          <thead className="all-tables-head">
                            <tr
                              role="row"
                              style={{ backgroundColor: textColor }}
                            >
                              <th className="center">
                                <span> Serial.No </span>
                                <span />
                              </th>
                              <th className="center">
                                <span> Date </span>
                                <span />
                              </th>

                              <th className="center">
                                <span> Project </span>
                                <span />
                              </th>
                              <th className="center">
                                <span> Task Description</span>
                                <span />
                              </th>
                              <th className="center">
                                <span>Task Status </span>
                                <span />
                              </th>
                              <th className="center multi-table" colSpan="2">
                                <>
                                  <table>
                                    <tbody>
                                      <tr
                                        role="row"
                                      >
                                        <td colSpan="2">
                                          <span> Duration </span>
                                        </td>
                                      </tr>
                                      <tr
                                        role="row"
                                      >
                                        <td style={{ width: "50%" }}>
                                          <span> From </span>
                                        </td>
                                        <td>
                                          <span> To </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              </th>
                              <th className="center">
                                <span>Hours Spend</span>
                                <span />
                              </th>
                              
                              <th className="center timesheet-comments">
                                <span> Comments </span>
                                <span />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTimesheet.map((record, index) => (
                              <tr key={index} className="">
                                <td className="">{index + 1}</td>
                                <td className="">{record[0]}</td>
                                <td className="">{record[1]}</td>
                                <td className="">{record[2]}</td>
                                <td className="">{record[3]}</td>
                                <td className="">{record[4]}</td>
                                <td className="">{record[5]}</td>
                                <td className="">{convertDecimalToHHMMSS(record[6])}</td>
                                <td className="">{record[7]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Form>
                    </div>
                  </div>
                  <div className="mt-4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PendingApproval;
