import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState, useContext } from "react";
import "./Calendar.css";
import { Link } from "react-router-dom";
import { EmplContext } from "../../Login_Page/Login_Page/LoginPage";
import configData from "../../../../src/config.json";
import Swal from "sweetalert2";
import { ColorContext } from "../../../ColorContext";

const Calender = () => {
  const [date, setDate] = useState(new Date());
  const { textColor } = useContext(ColorContext);


  const handleDateChange = (date) => {
    setDate(date);
  };
  const [halfDayClicked, setHalfDayClicked] = useState(false);
   const [remainingleaves, setRemainingLeaves] = useState("");
  const { resultData } = useContext(EmplContext);
  let absentDates = [];
  let presentDates = [];
  let autoLeaveDates = [];
  let rejectedautoLeaveDates = [];
  let appliedLeaveDate = [];
  let approvedLeaveDate = [];
  let rejectedLeaveDate = [];
  let holidayLeaveDate = [];
  let holidayLeaveDate1 = [];
  let todayLeaveDate = [];
  const employee_id = resultData.employee_id || 0;

  // Check if resultData and resultData.calender_yearly are defined
  
  if (resultData && resultData.calender_yearly) {
    resultData.calender_yearly.forEach((item) => {
      if (item[1] === "present") {
        presentDates.push(item[0]);
      }
      if (item[1] === "absent") {
        absentDates.push(item[0]);
      }
      if (item[1] === "autoleave") {
        autoLeaveDates.push(item[0]);
      }
      if (item[1] === "autoleavereject") {
        rejectedautoLeaveDates.push(item[0]);
      }
       // if (item[1] === "approved") {
      if (item[1].startsWith("approved") ) {
        approvedLeaveDate.push(item[0]);
      }
      // if (item[1] === "refused") {
      if (item[1].startsWith("refused") ) {
        rejectedLeaveDate.push(item[0]);
      }
      // if (item[1] === "pending") {
      if (item[1].startsWith("pending") ) {
        appliedLeaveDate.push(item[0]);
      }
      
      if (item[1].includes("today-leave") ) {
        // console.log("item",item[1],item[0])
        todayLeaveDate.push(item[0]);
      }
      
      if(item[1] === "holiday-public"){
        holidayLeaveDate.push(item[0]);
      }
      if (item[1] === "holiday-rh") {
        holidayLeaveDate1.push(item[0]);
      }
    });
  }
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  let openLeaveModal = (date) => {
    setIsLeaveModalOpen(true);
  };
  
  const closeLeaveModal = (e) => {
    setIsLeaveModalOpen(false);
    closeForm();
  };
  const toggleHalfDayClicked = () => setHalfDayClicked(!halfDayClicked);
  const [formData, setFormData] = useState({
    leaveType: "",
    dateFrom: "",
    dateTo: "",
    halfDay: false,
    shift: "",
    duration: 0,
    reason: "",
    attachment: null,
  });
  const closeForm = () => {
    setFormData({
      leaveType: "",
      dateFrom: "",
      dateTo: "",
      halfDay: false,
      shift: "",
      duration: 1,
      reason: "",
      attachment: null,
    });
    setHalfDayClicked(false);
  };
  const [fetchleave, setFetchleave] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("ev",e)

    if (fetchleave) return; // Prevent multiple requests

    setFetchleave(true); // Set fetchleave to true to indicate the request is in progress

    try {
      // console.log("formData====", formData);
      const secretKey = configData.SECRET_Key;
      const requestData = { formData, employee_id, secretKey };
      // console.log("formdata", formData)
      const response = await fetch(`${configData.SERVER_URL}/leave`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Failed to create leave");
      }
      if (response) {
        const data = await response.json();

        const result = JSON.parse(data.result);

        if (result.success === true) {
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: result.message,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error,
      });
    } finally {
      setFetchleave(false); // Reset fetchleave to false after the request is completed
    }
  };

// console.log("resultData.calender_yearly===",resultData.calender_yearly)
const getTileClassName = ({ date }) => {
  // Format the current date to match the format of status dates
  const formattedDate = `${date.getFullYear()}-${
    date.getMonth() + 1 <= 9
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1
  }-${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}`;
  

  if (absentDates.includes(formattedDate)) {
    return "absent";
  }
  if (presentDates.includes(formattedDate)) {
    return "present";
  }
  if (autoLeaveDates.includes(formattedDate)) {
    return "autoleave";
  }
  if (rejectedautoLeaveDates.includes(formattedDate)) {
    return "autoleavereject";
  }
  if (appliedLeaveDate.includes(formattedDate)) {
    if (todayLeaveDate.includes(formattedDate)) {
      return " applied today-leave";
    }
    return "applied";
  }
  if (approvedLeaveDate.includes(formattedDate)) {
    if (todayLeaveDate.includes(formattedDate)) {
      return "approved today-leave";
    }
    return "approved";
  }
  if (rejectedLeaveDate.includes(formattedDate)) {
    if (todayLeaveDate.includes(formattedDate)) {
      return "rejected today-leave";
    }
    return "rejected";
  }
  
  if(holidayLeaveDate.includes(formattedDate)){
    return "holiday-public";
  }
  if (holidayLeaveDate1.includes(formattedDate)) {
    return "holiday-rh";
  }
 

  return "";
};


  const handleChange = (e) => {
    const { name, checked, files, value } = e.target;
    // console.log("name, checked, value====",name, checked, value)
    if (e.target.name === "attachment") {
      const base64DataArray = [];

      // Read each file and push its base64 representation to base64DataArray
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          base64DataArray.push(reader.result);

          // Check if all files have been processed
          if (base64DataArray.length === files.length) {
            // Update the state after all files have been read
            setFormData({ ...formData, [name]: base64DataArray });
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (name === "halfDay") {
  
      // setFormData({ ...formData, [name]: checked === true ? checked : false });
      // toggleHalfDayClicked();

      if (formData.leaveType !== "8") {  // If it's not RH leave
        setFormData({ ...formData, [name]: checked === true ? checked : false });
        toggleHalfDayClicked();
      }
    } 
    else {
      let updatedFormData = { ...formData, [name]: value };
      // console.log("updatedFormData",updatedFormData)
      // console.log("name,value",name,value)
      if (name === "dateFrom" || name === "dateTo") {
        const startDate = new Date(updatedFormData.dateFrom);
        const endDate = new Date(updatedFormData.dateTo);

        if (name === "dateTo") {
          endDate.setHours(23, 59, 59, 999);
        }

        const totalDays = Math.round(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );

        let weekDays = 0;
        if (name === "dateFrom") {
          for (let i = 0; i <= totalDays; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              weekDays++;
            }
          }
        } else if (name === "dateTo") {
          for (let i = 0; i < totalDays; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
              weekDays++;
            }
          }
        }
        updatedFormData.duration = weekDays.toString();
      }
      setFormData(updatedFormData);
    }
  };
  

   function x(date){
    if(getTileClassName({date}) != "holiday-public"){
      openLeaveModal(date);
      setFormData((prevData) => ({
        ...prevData,
        dateFrom: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
        // dateTo: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
      }));
    }
    if(getTileClassName({date}) === "holiday-rh"){
      openLeaveModal(date);
      setFormData((prevData) => ({
        ...prevData,
        // dateFrom: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
        dateTo: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
        duration:1
      }));
    }
  }

  function rh(date){
    if(getTileClassName({date}) === "holiday-rh"){
      return true;
    }
  }


  // console.log("most_recent_holiday", resultData.most_recent_holiday);
  // console.log("calender_yearly", resultData.calender_yearly);
  return (
    
    <div className="ep_calender" style={{ "--text-color": textColor }}>
      {isLeaveModalOpen && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            onClick={closeLeaveModal}
          >
            <div
              className="modal-dialog"
              style={{ maxWidth: 750 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="modal-content"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Apply Leave
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeLeaveModal}
                  />
                </div>
                <form action="" method="post" onSubmit={handleLogin}>
                  <div className="modal-body o_form_view">
                    <table className="tb-view">
                      <tbody className="tb_body_content">
                        <tr className="td_leave">
                          <td className="htd">Time Off Type</td>
                          <td style={{ width: "100%", position: "relative" }}>
                            <div className="">
                              <select
                                id="leaveType"
                                name="leaveType"
                                value={formData.leaveType}
                                onChange={handleChange}
                                required
                              >
                                <option value="">select</option>
                                <option value="1">
                                  Paid Leave
                                  {/* ({remainingleaves} remaining out of{" "}
                                  {resultData.allocation_display}) */}
                                </option>
                                <option value="4">Unpaid Leave</option>
                                {rh(date) ? (
                                  <option value = "8">RH Leave</option>
                                ):null
                                
                                }
                                
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="td_leave">
                          <td className="htd">Dates</td>
                          <td style={{ width: "100%" }}>
                            <div className="divhtd">
                            {formData.leaveType !== "8" && (
                              <span className="htds">From </span>
                            )}
                              <input
                                type="date"
                                id="dateFrom"
                                name="dateFrom"
                                value={formData.dateFrom}
                                onChange={handleChange}
                                disabled={formData.leaveType === "8"}
                                required
                                // className="form-control"
                              />
                              {halfDayClicked ? (
                                <div
                                  className="leave_application"
                                  style={{ gap: "5px" }}
                                >
                                  <select
                                    id="shift"
                                    name="shift"
                                    value={formData.shift}
                                    onChange={handleChange}
                                    required={formData.halfDay}
                                    // className="form-control"
                                  >
                                    <option value=""></option>
                                    <option value="am">Morning</option>
                                    <option value="pm">Evening</option>
                                  </select>
                                </div>
                              ) : (
                                formData.leaveType !== "8" && (
                                <div className="leave_application">
                                  <span className="htds pl-2">To </span>
                                  <input
                                    type="date"
                                    id="dateTo"
                                    name="dateTo"
                                    value={formData.dateTo}
                                    onChange={handleChange}
                                    required
                                    // className="form-control"
                                  />
                                </div>)
                              )}
                            </div>
                            <div className="divhtd">
                              <div className="mr-2 mt-1">
                                <input
                                  type="checkbox"
                                  id="halfDay"
                                  name="halfDay"
                                  checked={formData.halfDay}
                                  onChange={handleChange}
                                  disabled={formData.leaveType === "8"} 

                                  // className="form-control"
                                />
                              </div>
                              <label
                                className="o_form_label"
                                htmlFor="halfDay"
                                data-original-title=""
                                title=""
                              >
                                Half Day
                              </label>
                            </div>
                          </td>
                        </tr>
                        {halfDayClicked === false && (
                          <tr className="td_leave">
                            <td className="htd">Duration</td>
                            <td style={{ width: "100%" }}>
                              <input
                                type="number"
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                readOnly
                                // className="form-control"
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="td_leave">
                          <td className="htd">Description</td>
                          <td style={{ width: "100%" }}>
                            <textarea
                              id="o_field_input_19"
                              // className="o_field_text o_field_widget o_quick_editable o_input"
                              name="reason"
                              value={formData.reason}
                              onChange={handleChange}
                              // required
                              // className="form-control"
                            />
                          </td>
                        </tr>
                        <tr className="td_leave">
                          <td className="htd">Attachment</td>
                          <td style={{ width: "100%", textAlign: "left" }}>
                            <input
                              type="file"
                              id="attachment"
                              name="attachment"
                              accept=".pdf, image/*"
                              onChange={handleChange}
                              multiple
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={closeLeaveModal}
                    >
                      Discard
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Apply
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      <div className="cal_body">
        <Calendar
          value={date}
          onChange={handleDateChange}
          onClickDay={(date) => x(date)}
          calendarType="gregory"
          next2Label={null}
          prev2Label={null}
          tileClassName={({ date }) => getTileClassName({ date })}
          minDetail="year"
        />

        
      </div>
      <div
        className="cal_footer"
        style={{
          display:
            resultData.most_recent_holiday &&
            resultData.most_recent_holiday[0][0]
              ? "flex"
              : "none",
        }}
      >
        <div className="cal_foot_left">
          <Link to="/holidays" className="cal_foot_link">
            All Holidays
          </Link>
        </div>
        {/* <div className="cal_foot_right">
          {resultData.most_recent_holiday &&
          resultData.most_recent_holiday[0][0] ? (
            <React.Fragment>
              <span className="cal_foot_text">
                {resultData.most_recent_holiday[0][0]}
              </span>
              <span className="cal_foot_text">
                {resultData.most_recent_holiday[0][1]}
              </span>
            </React.Fragment>
          ) : (
            <span>No Upcoming Holiday</span>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Calender;