import React, { useState, useEffect, useContext } from "react";
import HrDashboard_loading from "../HrDashboard/HrDashboard_loading";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../../src/config.json";
import Swal from "sweetalert2";
import { ColorContext } from "../../ColorContext";
import { NavLink } from "react-router-dom";
import { CustomerService } from "./service/CustomerService";
import "./ManagerDasboard.css";

export default function ApprovedTimesheet() {
  const [customers, setCustomers] = useState([]);
  const [requestedleaves, setRequestedleaves] = useState([]);
  const [load, setLoading] = useState(true);

  const { resultData } = useContext(EmplContext);
  const { textColor } = useContext(ColorContext);
  const employee_id = resultData.employee_id || 0;

  // State for expanding employees and dates
  const [expandedEmployee, setExpandedEmployee] = useState([]);
  const [expandedDate, setExpandedDate] = useState({});
  
  // State to handle if all employees are expanded
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  // State to handle if all dates are expanded for each employee
  const [isAllDatesExpanded, setIsAllDatesExpanded] = useState(false);

  // Function to toggle employees
  const toggleEmployee = (employeeName) => {
    setExpandedEmployee((prev) => {
      if (!Array.isArray(prev)) {
        console.warn("expandedEmployee should be an array");  // Optional debug check
        prev = [];
      }
      
      if (prev.includes(employeeName)) {
        // Remove employee from the list if already expanded
        return prev.filter((name) => name !== employeeName);
      } else {
        // Add employee to the expanded list
        return [...prev, employeeName];
      }
    });
  };
  

  // Function to toggle dates within an employee
  const toggleDate = (employeeName, date) => {
    setExpandedDate((prev) => ({
      ...prev,
      [employeeName]: prev[employeeName] === date ? null : date,
    }));
  };

  // Handle Approve / Reject action
  const handleTimesheetAction = async (timesheet_id, action) => {
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, timesheet_id, action };

      const response = await fetch(`${configData.SERVER_URL}/handletimesheet`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      const result = JSON.parse(data.result);

      if (result.success) {
        Swal.fire({ icon: "success", title: result.message });
      } else {
        Swal.fire({ icon: "error", title: result.message });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      fetchData();
    }
  };

  // Fetching data
  const fetchData = async () => {
    const secretKey = configData.SECRET_Key;
    try {
      const data = await CustomerService.getCustomersMedium(employee_id, secretKey);
      setCustomers(data.approved_timesheet_list);
      // setRequestedleaves(data.approved_timesheet_list);
    } catch (error) {
      Swal.fire({ icon: "error", title: error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [resultData.employee_id]);

  // Function to expand all employees and all dates at once
  const unfoldAllEmployeesAndDates = () => {
    const newAllExpanded = !isAllExpanded;  // Toggle the all expanded state
    setIsAllExpanded(newAllExpanded);
  
    // Expanding all employees or collapsing them based on the new state
    setExpandedEmployee((prev) =>
      newAllExpanded ? customers.map((timesheet) => timesheet[9]) : [] // Clear out previous expanded employees if collapsing
    );
    setIsAllDatesExpanded(newAllExpanded); // Expand dates as well
  };
  return (
    <>
      <div className="logo-hr-dashboard">
        <img src="company_logo.png" alt="Profile Pic" className="img-xs brand-logo-image" />
      </div>

      {load ? (
        <div className="">
        {/* <HrDashboard_loading /> */}
      </div>
      ) : (
        <div className="content-wrapper">
          <div className="ep_content">
            <div className="row" style={{ paddingTop: 70 }}>
              <div style={{textAlign:"right"}}>
              <button className="btn " onClick={unfoldAllEmployeesAndDates} style={{borderRadius:"25px",  backgroundColor: textColor}}>
                  {isAllExpanded ? "Collapse All" : "Unfold All"}
                </button>
              </div>
              <div className="col-md-12">
                <div className="tabs-container">
                  <NavLink to="/TeamTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{textDecoration:"none"}}>
                    Pending Timesheet
                  </NavLink>
                  <NavLink to="/ApprovedTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{textDecoration:"none"}}>
                    Approved Timesheet
                  </NavLink>
                  <NavLink to="/RejectedTimesheet" className={({ isActive }) => (isActive ? "tab active" : "tab")} style={{textDecoration:"none"}}>
                    Rejected Timesheet
                  </NavLink>
                </div>

                

                <div className="atable">
                  <table id="basicTable" className="basic-table">
                  <tbody>
                        {customers && Array.isArray(customers) && Object.entries(
                          customers.reduce((acc, timesheet) => {
                            const employeeName = timesheet[9]; 
                            const date = timesheet[10]; 

                            if (!acc[employeeName]) acc[employeeName] = {};
                            if (!acc[employeeName][date]) acc[employeeName][date] = [];

                            acc[employeeName][date].push(timesheet);
                            return acc;
                          }, {})
                        ).map(([employeeName, dateGroups], empIndex) => (
                          <React.Fragment key={empIndex}>
                            <tr
                              className="employee-row"
                              onClick={() => toggleEmployee(employeeName)}
                              style={{
                                backgroundColor: expandedEmployee.includes(employeeName) ? textColor : "initial",
                                cursor: "pointer",
                                fontWeight: "bold",
                                color: expandedEmployee.includes(employeeName) ? "textColor" : "textColor",
                              }}
                            >
                              <td colSpan="9" style={{textAlign:"left", backgroundColor: textColor, color:"white"}}>{employeeName}</td>
                            </tr>
                          
                            {(isAllExpanded || expandedEmployee.includes(employeeName)) &&
                              Object.entries(dateGroups).map(([date, timesheets], dateIndex) => (
                                <React.Fragment key={dateIndex}>
                                  <tr
                                    className="date-row"
                                    onClick={() => toggleDate(employeeName, date)}
                                    style={{
                                      backgroundColor:
                                        expandedDate[employeeName] === date ? "textColor" : "textColor",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <td colSpan="9" style={{textAlign:"left"}}>{date}</td>
                                  </tr>

                                  {(isAllDatesExpanded || expandedDate[employeeName] === date) && (
                                    <tr>
                                      <td colSpan="9">
                                        <table>
                                          <thead>
                                            <tr role="row" style={{ backgroundColor: textColor, color: "white" }}>
                                              <th className="center">S.No</th>
                                              <th className="center">Project</th>
                                              <th className="center">Task Description</th>
                                              <th className="center">From</th>
                                              <th className="center">To</th>
                                              <th className="center">Hours Spent</th>
                                              <th className="center">Comments</th>
                                              <th className="center">Performance</th>
                                              <th className="center">M.Comments</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {timesheets.map((timesheet, idx) => (
                                              <tr key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>{timesheet[0]}</td>
                                                <td>{timesheet[1]}</td>
                                                <td>{timesheet[2]}</td>
                                                <td>{timesheet[3]}</td>
                                                <td>{timesheet[4]}</td>
                                                <td>{timesheet[5]}</td>
                                                <td>{timesheet[6]}</td>
                                                <td>{timesheet[7]}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))} <tr style={{ height: "10px" }}></tr> 
                          </React.Fragment>
                        ))}
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
